<template>
    <div
        class="bg-gradient-to-br from-gray-100 via-gray-200 to-gray-500 h-screen"
    >
        <div class="flex h-1/3">
            <div class="w-1/3"></div>
            <div class="w-1/3 ">
                <img
                    alt="Vue logo"
                    class="mx-auto object-center my-32"
                    src="../assets/site_logo.png"
                />
            </div>
            <div class="w-1/3"></div>
        </div>

        <div class="flex">
            <div class="flex-shrink w-3/5"></div>
            <div class="text-center bg-white p-12 m-4 shadow center flex-grow ">
                <form ref="loginForm" class="pt-8" v-on:keyup.enter="login">
                    <t-input
                        v-model="loginDetails.username"
                        placeholder="Username"
                        type="text"
                        class="m-4 p-3"
                    ></t-input>
                    <t-input
                        v-model="loginDetails.password"
                        placeholder="Password"
                        type="password"
                        class="m-4 p-3 "
                    ></t-input>
                    <div></div>
                    <t-button
                        class="bg-green-600 hover:bg-green-400 rounded"
                        @click="login()"
                        type="button"
                        >Login</t-button
                    >
                </form>
            </div>
            <div class="flex-shrink w-3/5"></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
// import Vinput from "../components/Vinput";
// import Vbutton from "@/components/Vbutton";
// import Vcard from "@/components/Vcard";

export default {
    data() {
        return {
            fav: true,
            menu: false,
            message: false,
            hints: true,
            loginDetails: {
                username: "",
                password: ""
            },
            loading: false
        };
    },
    components: {
        // "v-input": Vinput,
        // "v-button": Vbutton,
        // "v-card": Vcard,
    },
    computed: {
        siteLogo() {
            return process.env.VUE_APP_SITE_LOGO || "site_logo.png";
        },
        ...mapState({
            token: state => state.account.token,
            error: state => state.account.error
        }),

        token: {
            get() {
                return this.$store.state.account.token;
            }
        },

        error: {
            get() {
                return this.$store.state.account.error;
            }
        }
    },
    methods: {
        ...mapActions({
            fetchToken: "account/fetchToken"
        }),

        ...mapMutations({
            setToken: "account/setToken",
            clearError: "account/clearError"
        }),

        async login() {
            this.loading = true;
            if (
                this.loginDetails.username != "" &&
                this.loginDetails.password != ""
            ) {
                await this.fetchToken(this.loginDetails);
            }
            if (this.token.accessToken != undefined) {
                this.$router.push("/");
            } else {
                this.loading = false;
                //this.$refs.loginForm.validate();
            }
        }
    }
};
</script>
